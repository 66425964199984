<template>
  <div>
    <a-anchor
      class="travel-anchor order-docs"
      :class="drag ? 'dragging' : ''"
      @change="smoothScroll"
    >
      <div class="title">Documentos</div>

      <draggable
        v-model="contractPages"
        @start="drag = true"
        @end="updatePageOrder((drag = false))"
        @change="updtPagesPosition"
      >
        <a-anchor-link
          v-for="(page, index) in contractPages"
          :key="index"
          :href="`#doc-${page.id}`"
        >
          <template slot="title">
            <a-checkbox
              class="mr-10"
              v-model="contractPages[index]['check']"
              style="font-size: 13px; font-weight: 500; position: relative"
            >
            </a-checkbox>

            <a-icon type="menu" class="mr-5" />
            <a-tooltip
              v-if="page.id == 9"
              placement="top"
              title="Ver dados do cartão"
            >
              <a-button
                type="primary"
                class="ml-5"
                @click="viewCreditCardData"
                shape="circle"
                size="small"
                icon="eye"
              />
            </a-tooltip>
            {{ page.title.replace("(SISTEMA) VR - ", "") }}
          </template>

          <a-anchor-link
            v-for="(subpage, index) in page.subpages"
            :key="index"
            :href="`#doc-${page.id}-${subpage.id}`"
          >
            <template slot="title">
              <a-checkbox
                class="mr-10"
                v-model="page.subpages[index]['check']"
                style="font-size: 13px; font-weight: 500; position: relative"
              >
              </a-checkbox>

              {{
                `${page.title.replace("(SISTEMA) VR - ", "")} #${subpage.id}`
              }}
            </template>
          </a-anchor-link>
        </a-anchor-link>
      </draggable>

      <div class="action">
        <div class="">
          <a-button
            size="default"
            type="primary"
            ghost
            @click="printDocs('CONTRATO DE VIAGENS')"
          >
            <a-icon type="printer" />
          </a-button>

          <a-button
            class="ml-10"
            @click="generatePDF('CONTRATO DE VIAGENS')"
            size="default"
            type="danger"
            :loading="generatePDFLoading"
            ghost
          >
            <a-icon type="file-pdf" /> PDF
          </a-button>
        </div>
      </div>
    </a-anchor>

    <a-form
      v-if="$root.isAdmin() && !loadingDocuments"
      :form="form"
      @submit="handleSubmit"
    >
      <a-form-item class="mt-20 mb-10 contract-docs">
        <a-select
          class="travel-input"
          show-search
          placeholder="Selecione os documentos para gerar"
          mode="multiple"
          style="font-size: 11px"
          size="small"
          v-decorator="[
            'contract_documents',
            {
              rules: [
                {
                  required: true,
                  message: 'Selecione os documentos que serão gerados.',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of documents.documentsSelector"
            :key="index"
            :value="item.id"
          >
            #{{ item.id }} - {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-button type="primary" html-type="submit"> Salvar documentos </a-button>
    </a-form>

    <a-modal
      title="Verificação "
      :visible="showPasswordModal"
      :footer="null"
      class="travel-modal"
      @cancel="showPasswordModal = false"
      width="300px"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <input
            name="email"
            style="
              color: #fff;
              opacity: 0;
              pointer-events: none;
              height: 0;
              padding: 0;
              border: 0;
            "
            placeholder="Fake email fix"
          />
          <a-input
            class="travel-input"
            v-model="userPassword"
            type="password"
            placeholder="Insira sua senha"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-col>
        <a-col :span="24">
          <input
            name="email"
            style="
              color: #fff;
              opacity: 0;
              pointer-events: none;
              height: 0;
              padding: 0;
              border: 0;
            "
            placeholder="Fake email fix"
          />
          <form>
            <a-input
              class="travel-input"
              name="auth-code"
              v-model="authCode"
              placeholder="Código de autorização"
            >
              <a-icon
                slot="prefix"
                type="idcard"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </form>
        </a-col>
        <a-col class="a-center" :span="24">
          <a-button
            @click="getCardData"
            class="mt-30"
            type="primary"
            :loading="getCardDataLoading"
          >
            Ver dados
          </a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import jq from "jquery";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
const doc = new jsPDF();

export default {
  name: "ContractDocumentsNavigationSection",
  props: {
    contract: Object,
    contrato: Object,
    contractPages: Array,
  },
  components: { draggable },
  mixins: [],
  data() {
    return {
      form: this.$form.createForm(this),
      drag: false,
      authCode: "",
      userPassword: "",
      loadingDocuments: false,
      showPasswordModal: false,
      generatePDFLoading: false,
      getCardDataLoading: false,
      updateTheCardData: false,
      documents: {
        documentsSelector: [],
        documentSelectorIsOpen: false,
        viajarResorts: [
          {
            id: 1,
            name: "Viajar Resorts - Documentos - Padrão",
          },
        ],
        voeSimples: [
          {
            id: 2,
            name: "Voe Simples - Documento - Federal",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.contract.company_branch_id != undefined) {
      this.getDocuments();
    }
  },
  beforeMount() {
    document.addEventListener("scroll", this.handleScroll);
    this.targetOffset = window.innerHeight / 2;

    doc.html(document.body, {
      callback: function (doc) {
        doc;
      },
    });
  },

  methods: {
    updatePageOrder() {
      let newPageOrder = [];
      this.contractPages.forEach((page) => {
        newPageOrder.push(page.id);
      });

      this.form.setFieldsValue({
        contract_documents: newPageOrder,
      });
    },
    updtPagesPosition() {
      this.$emit("updtPagesPosition", this.contractPages);
    },
    viewCreditCardData() {
      this.showPasswordModal = true;
      this.userPassword = " ";
      setTimeout(() => {
        this.userPassword = "";
      }, 400);
      //   console.log(this.cardID);
    },
    smoothScroll(e) {
      if (e) {
        let elementTop = document.getElementById(e.replace("#", "")).offsetTop;

        jq(".document-viewer").animate(
          {
            scrollTop: elementTop,
          },
          1000
        );
      }
    },
    generatePDF(docName) {
      this.generatePDFLoading = true;

      let name = "";

      if (this.contract.customer == false) {
        if (this.contract.meta.client_name != undefined) {
          name = `${this.contract.meta.client_name.toUpperCase()}`;
        }
      } else {
        if (this.contract.customer.person_type == "Pessoa Física") {
          name = `${this.contract.customer.first_name.toUpperCase()} ${this.contract.customer.last_name.toUpperCase()}`;
        } else {
          name = `${this.contract.customer.trading_name.toUpperCase()} `;
        }
      }

      let element = document.getElementById("the-outer-document");
      let options = {
        filename: `${this.contract.id} - ${docName} - ${name}.pdf`,
        margin: [8, 8, 8, 8],
        image: { type: "jpeg", quality: 1 },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
        html2canvas: {
          scale: 1,
          proxy: "http://localhost:8080",
          useCORS: true,
          allowTaint: true,
          scrollX: 0,
          scrollY: 0,
        },

        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
      };

      if (this.contrato.pagamento.credito.ativo) {
        if (
          this.contract.meta["payment_methods_credit_card_client_name_1"] &&
          this.contract.meta["payment_methods_credit_card_client_month_1"] &&
          this.contract.meta["payment_methods_credit_card_client_year_1"]
        ) {
          html2pdf().from(element).set(options).save();
          this.generatePDFLoading = false;
        } else {
          this.showPasswordModal = true;
          this.$message.warning(
            "Você está gerando o PDF sem exibir os dados de cartão do cliente. Insira sua senha para exibir os dados."
          );
          this.generatePDFLoading = false;
        }
      } else {
        html2pdf().from(element).set(options).save();
        this.generatePDFLoading = false;
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$http
            .post("/contract/update-documents", {
              contract_documents: JSON.stringify(values.contract_documents),
              id: this.contract.id,
              changed_by: this.$store.state.userData.id,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(({ data }) => {
              this.$message.success(data.message);
              setTimeout(() => {
                window.open("/contracts/view/" + this.contract.id, "_self");
              }, 500);
            });
        }
      });
    },
    getDocuments() {
      this.loadingDocuments = true;
      this.$http
        .get(
          `/document/list?page=1&per_page=1000&company_branch_id=${this.contract.company_branch_id}&status=1&is_general=1&html=no`
        )
        .then(({ data }) => {
          this.documents.documentsSelector = data.data;

          this.form.setFieldsValue({
            contract_documents: this.contract.meta.contract_documents
              ? JSON.parse(this.contract.meta.contract_documents)
              : [],
          });

          this.documents.documentsSelector.push({
            id: "10",
            name: `Anexo II - Terceiros `,
            company: "all",
            is_general: 1,
          });

          this.documents.documentsSelector.push({
            id: "9",
            name: `(SISTEMA) Autorização de Débito`,
            company: "all",
            is_general: 1,
          });

          this.loadingDocuments = false;
        });
    },
    getCardData() {
      // INSERIU A SENHA
      if (this.userPassword && this.authCode) {
        this.getCardDataLoading = true;
        this.$http
          .post("/contract/read_card", {
            email: this.$store.state.userData.email,
            password: this.userPassword,
            auth_code: this.authCode,
            contract_id: this.contract.id,
            card_id: 1,
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            this.$message.success(data.message);

            this.contract.meta = {
              ...this.contract.meta,
              ...data.card,
            };

            this.$emit("updateContractMeta", this.contract.meta);

            this.updateTheCardData = true;

            JSON.parse(this.contract.meta.credit_card_rows).forEach(
              (card, index) => {
                this.contrato.pagamento.credito.cartao[index].expiracao = `${
                  data.card[
                    `payment_methods_credit_card_client_month_${card.id}`
                  ]
                } / ${data.card[
                  `payment_methods_credit_card_client_year_${card.id}`
                ].slice(-2)}`;

                this.contrato.pagamento.credito.cartao[index].cvv = `${
                  data.card[
                    `payment_methods_credit_card_client_cvv_${card.id}`
                  ] != undefined
                    ? data.card[
                        `payment_methods_credit_card_client_cvv_${card.id}`
                      ]
                    : ""
                } `;

                this.contrato.pagamento.credito.cartao[index].nome = `${
                  data.card[
                    `payment_methods_credit_card_client_name_${card.id}`
                  ]
                } `.toUpperCase();

                this.contrato.pagamento.credito.cartao[
                  index
                ].bandeira = `${data.card[
                  `payment_methods_credit_card_client_flag_${card.id}`
                ].trim()}`;

                this.contrato.pagamento.credito.cartao[index].numeros.primeiro =
                  data.card[
                    `payment_methods_credit_card_client_number_${card.id}`
                  ].split(" ")[0];

                this.contrato.pagamento.credito.cartao[index].numeros.segundo =
                  data.card[
                    `payment_methods_credit_card_client_number_${card.id}`
                  ].split(" ")[1];

                this.contrato.pagamento.credito.cartao[index].numeros.terceiro =
                  data.card[
                    `payment_methods_credit_card_client_number_${card.id}`
                  ].split(" ")[2];

                this.contrato.pagamento.credito.cartao[index].numeros.quarto =
                  data.card[
                    `payment_methods_credit_card_client_number_${card.id}`
                  ].split(" ")[3];
              }
            );

            let updatedContractPages = this.contractPages.map((page) => {
              return {
                ...page,
                data: page.data.map((paragraph) => {
                  return {
                    id: paragraph.id,
                    p: this.renderCreditCardTags(paragraph.p),
                    css: paragraph.css,
                    page: paragraph.page,
                  };
                }),
              };
            });

            this.contractPages = updatedContractPages;
            this.getCardDataLoading = false;
            this.showPasswordModal = false;
            this.authCode = "";
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.getCardDataLoading = false;
          });
      } else {
        this.$message.error("Insira sua senha e o código de autorização.");
      }
    },
    renderCreditCardTags(paragraph) {
      return paragraph
        .replace(
          /\[CARTÃO DE CRÉDITO 1 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? `${this.contrato.pagamento.credito.cartao[0].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[0].numeros.segundo} ${this.contrato.pagamento.credito.cartao[0].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[0].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 CVV\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 1 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[0] != undefined
            ? this.contrato.pagamento.credito.cartao[0].bandeira
            : ""
        )

        .replace(
          /\[CARTÃO DE CRÉDITO 2 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? `${this.contrato.pagamento.credito.cartao[1].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[1].numeros.segundo} ${this.contrato.pagamento.credito.cartao[1].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[1].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 CVV\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 2 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[1] != undefined
            ? this.contrato.pagamento.credito.cartao[1].bandeira
            : ""
        )

        .replace(
          /\[CARTÃO DE CRÉDITO 3 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? `${this.contrato.pagamento.credito.cartao[2].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[2].numeros.segundo} ${this.contrato.pagamento.credito.cartao[2].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[2].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 CVV\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 3 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[2] != undefined
            ? this.contrato.pagamento.credito.cartao[2].bandeira
            : ""
        )

        .replace(
          /\[CARTÃO DE CRÉDITO 4 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? `${this.contrato.pagamento.credito.cartao[3].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[3].numeros.segundo} ${this.contrato.pagamento.credito.cartao[3].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[3].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 CVV\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 4 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[3] != undefined
            ? this.contrato.pagamento.credito.cartao[3].bandeira
            : ""
        )

        .replace(
          /\[CARTÃO DE CRÉDITO 5 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? `${this.contrato.pagamento.credito.cartao[4].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[4].numeros.segundo} ${this.contrato.pagamento.credito.cartao[4].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[4].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 CVV\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 5 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[4] != undefined
            ? this.contrato.pagamento.credito.cartao[4].bandeira
            : ""
        )

        .replace(
          /\[CARTÃO DE CRÉDITO 6 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? `${this.contrato.pagamento.credito.cartao[5].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[5].numeros.segundo} ${this.contrato.pagamento.credito.cartao[5].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[5].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 CVV\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 6 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[5] != undefined
            ? this.contrato.pagamento.credito.cartao[5].bandeira
            : ""
        )

        .replace(
          /\[CARTÃO DE CRÉDITO 7 EXPIRAÇÂO\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].expiracao
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 NUMEROS\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? `${this.contrato.pagamento.credito.cartao[6].numeros.primeiro} ${this.contrato.pagamento.credito.cartao[6].numeros.segundo} ${this.contrato.pagamento.credito.cartao[6].numeros.terceiro} ${this.contrato.pagamento.credito.cartao[6].numeros.quarto}`
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 NUMEROS 1-4\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].numeros.primeiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 NUMEROS 5-8\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].numeros.segundo
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 NUMEROS 9-12\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].numeros.terceiro
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 NUMEROS 13-16\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].numeros.quarto
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 CVV\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].cvv
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 NOME IMPRESSO\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].nome
            : ""
        )
        .replace(
          /\[CARTÃO DE CRÉDITO 7 BANDEIRA\]/g,
          this.contrato.pagamento.credito.cartao[6] != undefined
            ? this.contrato.pagamento.credito.cartao[6].bandeira
            : ""
        );
    },
  },
};
</script>

<style lang="sass" scoped></style>
